import React, {useState, useEffect} from "react"
import Layout from "src/components/core/layout/layout"
import withLocation from "src/components/search/with-location"
import {graphql} from 'gatsby';
import TagsWrapper from 'src/components/tags/tags-wrapper';
import PostsIndex from 'src/components/posts/posts-index';
import Tag from 'src/components/tags/tag';
import { Container, Row, Col } from 'react-grid-system';
import styled from 'styled-components';
import colors from 'src/components/core/theme/colors';

const Title = styled.h2`
  font-size: 1.5rem;
  color: ${colors.darkGray};
    font-family: "Crimson Text", "serif";
  font-weight: normal;
  margin-top: 0.1rem;
`


export const query = graphql`
  query PostsQuery {
      
    allPrismicPost(sort: {order: DESC, fields: data___date})  {
      nodes {
          uid
        data {
            date(formatString: "D MMMM, YYYY")
            
            subtitle{text}
          title {
            text
          }
          coverquote{
              text
          }
          cover {
            localFile {
              childImageSharp {
                fluid {
                  base64
                  tracedSVG
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  originalImg
                  originalName
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
          abstract {
              html
        }
        previewcover {
            md {
              url
            }
            sm {
              url
            }
          }
          body {
            text {
              html
              text
            }
            image {
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
          tags {
            tag {
              document {
                data {
                  tag
                }
                prismicId
              }
            }
          }
        }
        first_publication_date(formatString: "D MMMM, YYYY")
        prismicId
      }
    }
    allPrismicTag {
      nodes {
        data {
          tag
        }
        prismicId
      }
    }
    allPrismicBook(sort: {fields: data___order, order: ASC}) {
        nodes {
          uid
          data {
            title {
              text
            }
            previewavatar {
              localFile{
                  childImageSharp {
                    fluid {
                      base64
                      tracedSVG
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                      presentationWidth
                      presentationHeight
                    }
                  }
                }
            }
  
            previewcover {
                md {
                  url
                }
                sm {
                  url
                }
              }
            cover {
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
            body {
              text {
                html
                text
              }
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      base64
                      tracedSVG
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                      presentationWidth
                      presentationHeight
                    }
                  }
                }
              }
            }
            abstract {
              html
            }
          }
        }
      }
  }
`

const Posts = ({ data, search }) => {
  const tags = data.allPrismicTag.nodes
  const filterTag = tags.filter(tag => {return tag.data.tag === search.tag})
  const posts = Object.keys(search).length !== 0  
    ? data.allPrismicPost.nodes.filter(node =>
         node.data.tags.some(
          tag => tag.tag && tag.tag.document[0].prismicId === filterTag[0].prismicId
        )
      )
    : data.allPrismicPost.nodes
    const bookAndPosts = []
    if(filterTag && filterTag[0] && filterTag[0].data.tag === "Gods of Blood and Water"){
        bookAndPosts.push(data.allPrismicBook.nodes.filter(node=>node.uid==="frontinus"))
    }else if(filterTag && filterTag[0] && filterTag[0].data.tag === "The Prophet of Pompeii"){
        bookAndPosts.push(data.allPrismicBook.nodes.filter(node=>node.uid==="capra"))
    }else if(filterTag && filterTag[0] && filterTag[0].data.tag === "Corbulo's Daughter"){
        bookAndPosts.push(data.allPrismicBook.nodes.filter(node=>node.uid==="mita"))
    }
    bookAndPosts.push(posts)
    const flatBookAndPosts = bookAndPosts.length
    ? bookAndPosts.reduce((acc, val) => acc.concat(val), [])
    : null
    const [loaded, setLoaded] = useState(false)
    useEffect(()=>setLoaded(true), [])
  return !loaded ? <Layout></Layout> : (
      <Layout 
      title='Posts'
      description='The latest blog posts about the Roman empire'>
      <TagsWrapper>{tags.map(tag=><Tag tag={tag.data.tag}>{tag.data.tag}</Tag>)}</TagsWrapper>
      <PostsIndex posts={flatBookAndPosts} />
    </Layout>
  )
}

export default withLocation(Posts)
